<template>
  <v-dialog
    persistent
    scrollable
    width="700"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title>Registro da sessão</v-card-title>
      <v-card-text>
        <v-row class="mt-1">
          <v-col class="align-center">
            <v-icon class="mr-4">mdi-account</v-icon>
            <h3 class="d-inline">{{ customerName }}</h3>
          </v-col>
        </v-row>
        <v-row class="mt-1">
          <v-col class="align-center">
            <v-chip-group mandatory v-model="session.status">
              <v-chip small filter :value="'pending'">Pendente</v-chip>
              <v-chip small filter :value="'missed'" active-class="secondary">
                Faltou
              </v-chip>
              <v-chip small filter :value="'shown'" active-class="primary">
                Compareceu
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row class="mt-1" v-if="canEditSessionAnnotations">
          <v-col cols="12" class="align-center">
            <v-textarea
              v-model="session.annotations"
              solo
              flat
              placeholder="Faça aqui suas anotações..."
              background-color="grey lighten-4"
              no-resize
              hide-details
              rows="15"
            />
            <template v-if="hasCustomer">
              <v-btn
                @click="handleFileForm"
                small
                outlined
                color="primary"
                class="mt-5 mb-4"
              >
                <v-icon left>mdi-paperclip</v-icon>
                Anexar Arquivos
              </v-btn>

              <app-pagination
                v-if="hasFiles"
                :showCount="false"
                @click="selectFiles($event)"
                :data="files"
              >
                <v-list dense>
                  <v-list-item-group>
                    <v-divider />

                    <template v-for="(file, index) in files.data">
                      <v-list-item class="pr-0" :key="index">
                        <v-list-item-content>
                          <v-list-item-title>{{ file.name }}</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ $format.bytesToMB(file.size) }}
                            -
                            {{ $format.dateBr(file.created_at) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div class="d-flex">
                            <v-btn
                              @click.stop="handleDownloadFile(file)"
                              text
                              icon
                              color="primary"
                            >
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                            <app-delete-btn
                              @click="handleDeleteFile(file.id)"
                              type="icon"
                            />
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider :key="'divider' + file.id" />
                    </template>
                  </v-list-item-group>
                </v-list>
              </app-pagination>
            </template>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <span class="info--text">
              <app-icon class="info--text">info</app-icon>
              Somente o profissional da sessão pode criar anotações da sessão.
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions id="form-card-footer" class="mt-0 pa-4">
        <v-btn @click="handleClose()" text> Voltar </v-btn>
        <v-btn @click="update()" color="primary"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
    <template v-if="hasCustomer">
      <FileUploadForm
        @store="selectFiles()"
        ref="FileUploadForm"
        title="Anexar Arquivos à Sessão"
        :params="fileParams"
        :url="url"
      />
    </template>
    <AppConfirmDiscardChanges ref="appConfirmDiscardChanges" />
  </v-dialog>
</template>

<script>
import FileUploadForm from "@/components/app/forms/FileUploadForm";
import AppConfirmDiscardChanges from "@/components/app/sections/AppConfirmDiscardChanges";

export default {
  components: { FileUploadForm, AppConfirmDiscardChanges },
  data() {
    return {
      url: "/customer/customer-files",
      session: {
        professional: {},
        status: "shown",
        customer: {},
        session_group: {},
        annotations: null,
      },

      files: {},
      dialog: false,
    };
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    customerName() {
      return this.session.origin == "booking"
        ? this.session.booking.name
        : this.session.customer.name;
    },

    hasCustomer() {
      return this.session.customer_id != null;
    },

    canEditSessionAnnotations() {
      return this.user.id == this.session.professional.id;
    },

    fileParams() {
      return {
        session_id: this.session.id,
        customer_id: this.session.customer.id,
        professional_id: this.session.professional.id,
        collection: "session",
      };
    },

    hasFiles() {
      return this.files.data && this.files.data.length > 0;
    },

    hasUnsavedChanges() {
      return this.$store.state.app.hasUnsavedChanges;
    },
  },

  watch: {
    session: {
      handler(form) {
        this.$store.dispatch("app/hasChanges");
      },
      deep: true,
    },
  },

  methods: {
    async open(session) {
      this.session = JSON.parse(JSON.stringify(session));

      await this.selectFiles();

      this.$store.dispatch("app/resetChanges");

      this.dialog = true;
    },

    handleClose() {
      if (this.hasUnsavedChanges) {
        this.$refs.appConfirmDiscardChanges
          .askForSave()
          .then((res) => {
            this.update();
          })
          .catch((error) => {
            this.close();
          });
      } else {
        this.close();
      }
    },

    close() {
      this.$store.dispatch("app/resetChanges");

      this.dialog = false;
    },

    update() {
      this.$http
        .update("sessions/session-record", this.session.id, this.session)
        .then((response) => {
          console.log(response);
          this.processSaved();
        });
    },

    processSaved() {
      this.$emit("store", this.session.id);

      this.$store.dispatch("app/resetChanges");

      this.dialog = false;
    },

    handleFileForm() {
      this.$refs.FileUploadForm.open();
    },

    async selectFiles(page) {
      if (this.hasCustomer) {
        this.fileParams.page = page;
        this.$loading.start();
        await this.$http
          .index(this.url, this.fileParams)
          .then((response) => {
            this.files = response.files;
            this.$loading.finish();
          })
          .catch((error) => {
            this.$loading.finish();
          });
      }
    },

    handleDownloadFile(file) {
      this.$loading.start();
      this.$http
        .download(`${this.url}/download`, { id: file.id })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file.name);
          document.body.appendChild(link);
          link.click();
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleDeleteFile(document_id) {
      this.$loading.start();
      this.$http
        .destroy(this.url, document_id)
        .then((response) => {
          this.selectFiles();
          this.$loading.finish();
        })
        .catch((error) => {
          this.selectFiles();
          this.$loading.finish();
        });
    },
  },
};
</script>
