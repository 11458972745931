import { parseISO, addMinutes, parse, format, addDays, addMonths } from "date-fns";

export default {
  
  methods: {
    setSessionDates(session, sessionDuration) {
      session.start_at_time = this.addZeroSeconds(session.start_at_time)
      session.start_at = this.joinDateTime(session.start_at_date, session.start_at_time)

      let start_at_obj = parseISO(session.start_at)

      let end_at_obj = addMinutes(start_at_obj, sessionDuration) 

      session.end_at = this.formatDateTime(end_at_obj)
      session.end_at_date = this.formatDate(end_at_obj)
      session.end_at_time = this.formatTime(end_at_obj)

      return session
    },
    
    addZeroSeconds(value) {
      return value + ':00'
    },

    joinDateTime(date, time) {
      return date + ' ' + time
    },

    formatDateTime(dateObj) {
      return format(dateObj, 'yyyy-MM-dd HH:mm:ss') 
    },

    formatDate(dateObj) {
      return format(dateObj, 'yyyy-MM-dd')
    },

    formatTime(dateObj) {
      return format(dateObj, 'HH:mm:ss')
    },

    calcSessionRepetitions() {

    },

    addDaysFromFrequency(dateObj, frequency, sessionNumber) {
      if (frequency == "weekly") {
        return addDays(dateObj, 7 * sessionNumber);
      }

      if (frequency == "biweekly") {
        return addDays(dateObj, 14 * sessionNumber);
      }

      if (frequency == "monthly") {
        return addMonths(dateObj, 1 * sessionNumber);
      }
    },
    
    // getDate(dateTime) {
    //   if (dateTime.date != null && dateTime.time != '') {
    //     return dateTime.date + 'T' + dateTime.time

    //   }
    //   else {
    //     var hora = new Date().getHours() + ":" + new Date().getMinutes();
    //     return dateTime.date + "T" + hora;
    //   }
    // },
  }
}