<template>
  <div>
    <v-icon
      class="mr-2"
      v-text="statuses[session.status_by_customer].icon"
      :color="statuses[session.status_by_customer].color"
      small
    />
    <small :style="`color:${statuses[session.status_by_customer].color}`">
      {{ statuses[session.status_by_customer].text }}</small
    >
  </div>
</template>

<script>
import { customerAlias } from "@/utils/customerAlias";

export default {
  props: {
    session: {},
  },

  data: () => ({
    statuses: {
      confirmed: {
        text: `A sessão foi confirmada pelo ${customerAlias()} `,
        icon: "mdi-thumb-up-outline",
        color: "#00A884",
      },
      canceled: {
        text: `A sessão foi cancelada pelo ${customerAlias()} `,
        icon: "mdi-emoticon-confused-outline",
        color: "red",
      },
    },
  }),
};
</script>

<style>
</style>