<template>
  <div>
    <template v-if="isMobile">
      <Mobile />
    </template>

    <template v-if="!isMobile">
      <Desktop />
    </template>
  </div>
</template>

<script>
import Desktop from "@/components/schedule/views/Desktop";
import Mobile from "@/components/schedule/views/Mobile";
export default {
  components: {
    Desktop,
    Mobile,
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style>
</style>