export default {
  beforeRouteLeave: function(to, from, next) {
    if (this.$store.state.app.hasUnsavedChanges) {
      window.dispatchEvent(new Event("beforeunload"));
      next(false);
    } else {
      next();
    }
  },
};
