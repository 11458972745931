var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","fullscreen":_vm.isMobile,"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Filtros")]),_c('v-card-text',[(_vm.hasTags)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-group',{attrs:{"value":_vm.tags.length <= 10},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-tag")])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Tags ")])],1)]},proxy:true}],null,false,3752934230)},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"inactive":""},on:{"click":function($event){return _vm.selectAllTags()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticStyle:{"margin-right":"5px","transform":"scale(0.8)"}},[_c('v-checkbox',{attrs:{"indeterminate":_vm.selectedTagsCount !== 0 &&
                      _vm.selectedTagsCount !== _vm.tags.length,"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.selectAllText(_vm.searchParams.tags, _vm.tags))+" ")])],1)]}}],null,false,3233090162)})],1),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.searchParams.tags),callback:function ($$v) {_vm.$set(_vm.searchParams, "tags", $$v)},expression:"searchParams.tags"}},_vm._l((_vm.tags),function(row,index){return _c('v-list-item',{key:index,attrs:{"value":row.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var active = ref.active;
return [_c('v-list-item-action',{staticStyle:{"margin-right":"5px","transform":"scale(0.8)"}},[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":row.color}},[_vm._v(" mdi-tag ")]),_vm._v(" "+_vm._s(row.name)+" ")],1)],1)]}}],null,true)})}),1)],1)],1):_vm._e(),(_vm.showProfessionalsList)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-group',{attrs:{"value":_vm.professionals.length <= 10},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('app-icon',[_vm._v("badge")])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Profissionais ")])],1)]},proxy:true}],null,false,1362115115)},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"inactive":""},on:{"click":function($event){return _vm.selectAllProfessionals()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var active = ref.active;
return [_c('v-list-item-action',{staticStyle:{"margin-right":"5px","transform":"scale(0.8)"}},[_c('v-checkbox',{attrs:{"indeterminate":_vm.selectedProfessionalsCount !== 0 &&
                      _vm.selectedProfessionalsCount !== _vm.professionals.length,"input-value":!active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.selectAllText(_vm.searchParams.professionals, _vm.professionals))+" ")])],1)]}}],null,false,3335228106)})],1),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.searchParams.professionals),callback:function ($$v) {_vm.$set(_vm.searchParams, "professionals", $$v)},expression:"searchParams.professionals"}},_vm._l((_vm.professionals),function(row,index){return _c('v-list-item',{key:index,attrs:{"value":row.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var active = ref.active;
return [_c('v-list-item-action',{staticStyle:{"margin-right":"5px","transform":"scale(0.8)"}},[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(row.name)+" ")])],1)]}}],null,true)})}),1)],1)],1):_vm._e(),(_vm.showProfessionalsList && _vm.hasOffices)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-group',{attrs:{"value":_vm.offices.length <= 10},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('app-icon',[_vm._v("mdi-chair-rolling")])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Salas ")])],1)]},proxy:true}],null,false,1834981023)},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"inactive":""},on:{"click":function($event){return _vm.selectAllOffices()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var active = ref.active;
return [_c('v-list-item-action',{staticStyle:{"margin-right":"5px","transform":"scale(0.8)"}},[_c('v-checkbox',{attrs:{"indeterminate":_vm.selectedOfficesCount !== _vm.offices.length &&
                      _vm.selectedOfficesCount !== 0,"input-value":!active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.selectAllText(_vm.searchParams.offices, _vm.offices))+" ")])],1)]}}],null,false,492706305)})],1),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.searchParams.offices),callback:function ($$v) {_vm.$set(_vm.searchParams, "offices", $$v)},expression:"searchParams.offices"}},_vm._l((_vm.offices),function(row,index){return _c('v-list-item',{key:index,attrs:{"value":row.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var active = ref.active;
return [_c('v-list-item-action',{staticStyle:{"margin-right":"5px","transform":"scale(0.8)"}},[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(row.name)+" ")])],1)]}}],null,true)})}),1)],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleSearch()}}},[_vm._v(" Aplicar filtros ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }