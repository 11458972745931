<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :fullscreen="isMobile"
    max-width="500px"
  >
    <v-card>
      <v-card-title class="headline">Filtros</v-card-title>
      <v-card-text>
        <v-list v-if="hasTags" dense>
          <v-list-group :value="tags.length <= 10">
            <template v-slot:prependIcon>
              <v-icon>mdi-tag</v-icon>
            </template>

            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> Tags </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-group>
              <v-list-item inactive @click="selectAllTags()">
                <template v-slot:default="{ active }">
                  <v-list-item-action
                    style="margin-right: 5px; transform: scale(0.8)"
                  >
                    <v-checkbox
                      :indeterminate="
                        selectedTagsCount !== 0 &&
                        selectedTagsCount !== tags.length
                      "
                      :input-value="active"
                      color="primary"
                    />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ selectAllText(searchParams.tags, tags) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>

            <v-list-item-group v-model="searchParams.tags" multiple>
              <v-list-item
                v-for="(row, index) in tags"
                :key="index"
                :value="row.id"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action
                    style="margin-right: 5px; transform: scale(0.8)"
                  >
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon class="mr-2" small :color="row.color">
                        mdi-tag
                      </v-icon>
                      {{ row.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
        </v-list>

        <v-list v-if="showProfessionalsList" dense>
          <v-list-group :value="professionals.length <= 10">
            <template v-slot:prependIcon>
              <app-icon>badge</app-icon>
            </template>

            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> Profissionais </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item-group>
              <v-list-item inactive @click="selectAllProfessionals()">
                <template v-slot:default="{ active }">
                  <v-list-item-action
                    style="margin-right: 5px; transform: scale(0.8)"
                  >
                    <v-checkbox
                      :indeterminate="
                        selectedProfessionalsCount !== 0 &&
                        selectedProfessionalsCount !== professionals.length
                      "
                      :input-value="!active"
                      color="primary"
                    />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{
                        selectAllText(searchParams.professionals, professionals)
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>

            <v-list-item-group v-model="searchParams.professionals" multiple>
              <v-list-item
                v-for="(row, index) in professionals"
                :key="index"
                :value="row.id"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action
                    style="margin-right: 5px; transform: scale(0.8)"
                  >
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ row.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
        </v-list>

        <v-list v-if="showProfessionalsList && hasOffices" dense>
          <v-list-group :value="offices.length <= 10">
            <template v-slot:prependIcon>
              <app-icon>mdi-chair-rolling</app-icon>
            </template>

            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> Salas </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item-group>
              <v-list-item inactive @click="selectAllOffices()">
                <template v-slot:default="{ active }">
                  <v-list-item-action
                    style="margin-right: 5px; transform: scale(0.8)"
                  >
                    <v-checkbox
                      :indeterminate="
                        selectedOfficesCount !== offices.length &&
                        selectedOfficesCount !== 0
                      "
                      :input-value="!active"
                      color="primary"
                    />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ selectAllText(searchParams.offices, offices) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>

            <v-list-item-group v-model="searchParams.offices" multiple>
              <v-list-item
                v-for="(row, index) in offices"
                :key="index"
                :value="row.id"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action
                    style="margin-right: 5px; transform: scale(0.8)"
                  >
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ row.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="dialog = false" text> Cancelar </v-btn>
        <v-btn @click="handleSearch()" class="mr-2" color="primary">
          Aplicar filtros
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    dialog: false,
    searchParams: {
      tags: [],
      professionals: [],
      offices: [],
    },
  }),
  watch: {
    value() {
      this.searchParams = this.value;
    },
  },

  created() {
    this.searchParams = this.value;
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    professionals() {
      return this.$attrs.professionals ?? [];
    },
    offices() {
      return this.$attrs.offices ?? [];
    },
    tags() {
      return this.$attrs.tags ?? [];
    },
    showProfessionalsList() {
      return this.$attrs.showProfessionalsList ?? false;
    },
    hasTags() {
      return this.$attrs.hasTags ?? false;
    },
    hasOffices() {
      return this.$attrs.hasOffices ?? false;
    },

    selectedTagsCount() {
      return this.searchParams.tags.length ?? 0;
    },
    selectedProfessionalsCount() {
      return this.searchParams.professionals.length ?? 0;
    },
    selectedOfficesCount() {
      return this.searchParams.offices.length ?? 0;
    },
  },

  methods: {
    open() {
      this.dialog = true;
    },
    selectAllTags() {
      if (this.selectedTagsCount == this.tags.length) {
        this.searchParams.tags = [];
      } else {
        this.searchParams.tags = this.tags.map((row) => row.id);
      }
    },

    selectAllProfessionals() {
      if (this.selectedProfessionalsCount == this.professionals.length) {
        this.searchParams.professionals = [];
      } else {
        this.searchParams.professionals = this.professionals.map(
          (row) => row.id
        );
      }
    },

    selectAllOffices() {
      if (this.selectedOfficesCount == this.offices.length) {
        this.searchParams.offices = [];
      } else {
        this.searchParams.offices = this.offices.map((row) => row.id);
      }
    },

    selectAllText(selected, all) {
      return selected.length === all.length
        ? "Desmarcar todos"
        : "Marcar todos";
    },
    handleSearch() {
      this.$emit("input", this.searchParams);
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>