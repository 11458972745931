<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span> {{ title }} </span>
      </v-card-title>

      <v-card-text>
        <v-row v-if="hasFile">
          <v-col>
            <v-list dense>
              <v-list-item
                dense
                two-line
                v-for="(file, index) in rawFiles"
                :key="index"
              >
                <v-list-item-avatar>
                  <v-icon
                    :class="`${
                      isFileTooBig(file) ? 'red' : 'primary'
                    }  lighten-1`"
                    dark
                  >
                    {{ `mdi-${isFileTooBig(file) ? "alert" : "file"} ` }}
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ file.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="!isFileTooBig(file)">
                      {{ $format.bytesToSize(file.size) }}
                    </span>
                    <span v-if="isFileTooBig(file)">
                      Arquivo muito grande
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn @click="handleRemoveFile(index)" icon>
                    <v-icon color="red">mdi-trash-can</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-column align-center text-center">
              <template v-if="!hasFile">
                <v-avatar size="80px" color="#EDEAFC">
                  <v-icon color="primary" size="50px">
                    mdi-cloud-upload-outline
                  </v-icon>
                </v-avatar>
                <br />
              </template>

              <v-btn @click="handleAddFile" color="primary">
                <app-icon lef>add</app-icon> Adicionar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="dialog = false"> Cancelar </v-btn>
        <v-btn :disabled="!hasFile" color="primary" @click="handleSave()">
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-file-input
      class="d-none"
      ref="fileInput"
      :accept="accepts"
      counter
      @change="handleFileInput($event)"
      id="assetsFieldHandle"
    />
  </v-dialog>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      required: true,
      default: () => ({
        collection: null,
        session_id: null,
        customer_id: null,
        transaction_id: null,
        professional_id: null,
      }),
    },

    url: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    rawFiles: [],
    rawFile: null,

    accepts:
      "image/*,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.txt",
  }),

  computed: {
    title() {
      return this.$attrs.title || "Salvar em Nuvem";
    },

    hasFile() {
      return this.rawFiles.length > 0;
    },
  },

  created() {},

  methods: {
    open() {
      this.rawFiles = [];
      this.rawFile = null;
      this.dialog = true;
    },

    handleAddFile() {
      this.$refs.fileInput.$refs.input.click();
    },
    handleRemoveFile(index) {
      this.$delete(this.rawFiles, index);
    },

    handleFileInput(rawFile) {
      this.rawFiles.push(rawFile);
    },

    isFileTooBig(rawFile) {
      return rawFile.size > 10000000;
    },

    async handleSave() {
      try {
        await this.rawFiles.forEach((rawFile, index) => {
          this.$loading.start();

          if (!this.isFileTooBig(rawFile)) {
            this.sendFile(rawFile)
              .then((response) => {
                if (this.rawFiles.length - 1 == index) {
                  this.processSave();
                }
              })
              .catch((error) => {
                this.processErrorSendFile(error, rawFile);
              });
          }
        });
      } catch (error) {
        this.processErrorSendFile(error, rawFile);
      }
    },

    sendFile(rawFile) {
      return new Promise((resolve, reject) => {
        this.$http
          .store(this.url, this.getFormData(rawFile))
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getFormData(rawFile) {
      let form = new FormData();
      form.append("rawFile", rawFile);

      for (let key in this.params) {
        form.append(key, this.params[key]);
      }
      return form;
    },

    processErrorSendFile(error, rawFile) {
      console.log(
        "%c Tá pegando fogo bixo! ",
        "background: #f50505; color: #fff",
        error
      );
      this.$loading.finish();
      this.$snackbar({
        message: `Não foi possível enviar o arquivo ${rawFile.name}`,
        color: "error",
        timeout: 1000,
        showClose: false,
      });
    },

    processSave() {
      this.$loading.finish();
      this.dialog = false;
      // this.$snackbar({
      //   message: "Anamnese apagada com sucesso",
      //   color: "success",
      //   timeout: 1000,
      //   showClose: false,
      // });
      this.$emit("store");
    },
  },
};
</script>

<style>
</style>