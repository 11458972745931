
export default {
  
  data : () => ({
    oldDragEvent: null,
    dragEvent: null,
    dragTime: null,
  }),

  methods: {
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.oldDragEvent = JSON.parse(JSON.stringify(event));
        //this.oldDragEvent.start = getUnixTime(this.oldDragEvent.start)
        //this.dragTime = null;
        //this.extendOriginal = null;
      }
    },

    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      }
    },

    mouseMove(tms) {
      //console.log(tms);
      const mouse = this.toTime(tms);

      if (this.dragEvent) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      }
    },

    endDrag(event) {
      if (this.dragEvent) {
        if (this.dragEvent.start != this.oldDragEvent.start) {
          // alert('mudou')
          // console.log('changed...')

          this.rescheduleSession(this.dragEvent);
        } else {
          this.$refs.SessionDialog.open(this.oldDragEvent.session.id);
          this.resetDrag();
        }
      } else {
        if (this.showNewSessionForm) {
          this.$refs.StoreSessionForm.openFormDate(event.date, event.time);
        }

      }

      this.resetDrag();
    },

    mouseMoveDay(tms) { },

    cancelDrag() {
      if (this.dragEvent) {
        this.dragEvent.start = this.oldDragEvent.start;
        this.dragEvent.end = this.oldDragEvent.end;

        this.resetDrag();
      }
    },

    resetDrag() {
      this.oldDragEvent = null;
      this.dragEvent = null;
      this.dragTime = null;
    },

    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },

    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
  }
}