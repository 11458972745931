<template>
  <v-card outlined style="border-radius: 16px">
    <v-card-text>
      <div class="px-2 pt-2">
        <NewBookingsAlert
          v-if="haveUnreadBookings"
          :unreadBookings="unreadBookings"
          @show="handleOpenNewBookingsDialog()"
        />
      </div>

      <NewBookingsDialog
        :unreadBookings="unreadBookings"
        ref="NewBookingsDialog"
        @close="markBookingAsRead()"
      />

      <DesktopSessionCalendar
        :daysWithEvents="daysWithEvents"
        :sessions="sessions"
        :professionals="professionals"
        :offices="offices"
        :tags="tags"
        :holidays="holidays"
        :isClinic="isClinic"
        @change="handleChange($event)"
        @eventUpdate="handleEventUpdate()"
        @miniCalendarUpdate="miniCalendarUpdate($event)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import NewBookingsDialog from "@/components/schedule/sections/NewBookingsDialog.vue";
import NewBookingsAlert from "@/components/schedule/sections/NewBookingsAlert.vue";
import DesktopSessionCalendar from "@/components/schedule/sections/DesktopSessionCalendar.vue";
import CheckUnsavedChangesMixin from "@/mixins/App/CheckUnsavedChangesMixin";

import axios from "axios";

import {
  format,
  parse,
  parseISO,
  lastDayOfMonth,
  startOfMonth,
} from "date-fns";

export default {
  mixins: [CheckUnsavedChangesMixin],

  components: {
    NewBookingsDialog,
    NewBookingsAlert,
    DesktopSessionCalendar,
    CheckUnsavedChangesMixin,
  },

  data: () => ({
    params: {
      start_date: null,
      end_date: null,
      professionals: null,
      offices: [],
      tags: null,
    },

    miniCalendarActiveMonth: null,

    daysWithEvents: [],

    sessions: [],

    professionals: [],

    holidays: [],

    selectedYearsHolidays: [],

    unreadBookings: [],
  }),

  computed: {
    isClinic() {
      return this.$store.state.auth.company.type == "clinic";
    },

    user() {
      return this.$store.state.auth.user;
    },

    offices() {
      return this.$store.state.office.offices;
    },

    tags() {
      return this.$store.state.tags.tags;
    },

    haveUnreadBookings() {
      return this.unreadBookings.length > 0;
    },
  },

  mounted() {
    this.selectProfessionals();
    this.getUnreadBookings();
  },

  watch: {
    params: {
      deep: true,
      handler() {
        this.select();
        this.selectHolidays();
        this.selectDaysWithSessions();
      },
    },

    miniCalendarActiveMonth: {
      handler() {
        this.selectDaysWithSessions();
      },
    },
  },

  methods: {
    openSessionForm() {
      this.$refs.SessionForm.open();
    },

    handleChange(event) {
      this.params.start_date = event.start_date;
      this.params.end_date = event.end_date;
      this.params.professionals = event.professionals;
      this.params.offices = event.offices;
      this.params.tags = event.tags;
    },

    handleEventUpdate() {
      this.select();
      this.selectDaysWithSessions();
    },

    select() {
      this.$loading.start();
      this.$http
        .index("sessions/calendar", this.params)
        .then((response) => {
          this.sessions = response.sessions;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    selectProfessionals() {
      if (this.isClinic && this.$acl.can("can_manage_all_calendar")) {
        this.$http
          .index("user/users", {
            is_professional: 1,
            status: "active",
            page: 1,
          })
          .then((response) => {
            this.professionals = response.users.data;
          });
      } else {
        this.professionals = [this.user];
        this.params.professionals = [this.user.id];
      }
    },

    async selectHolidays() {
      let startYear = parseISO(this.params.start_date).getFullYear();
      let endYear = parseISO(this.params.end_date).getFullYear();

      if (!this.selectedYearsHolidays.includes(startYear)) {
        this.selectedYearsHolidays.push(startYear);

        this.holidays = [
          ...this.holidays,
          ...(await this.selectHolidaysByYear(startYear)),
        ];
      }

      if (!this.selectedYearsHolidays.includes(endYear)) {
        this.selectedYearsHolidays.push(endYear);

        this.holidays = [
          ...this.holidays,
          ...(await this.selectHolidaysByYear(endYear)),
        ];
      }
    },

    async selectHolidaysByYear(year) {
      let response = await axios.get(
        `https://brasilapi.com.br/api/feriados/v1/${year}`
      );

      return response.data;
    },

    miniCalendarUpdate(event) {
      this.miniCalendarActiveMonth = event;
    },

    selectDaysWithSessions() {
      let month = parse(this.miniCalendarActiveMonth, "yyyy-MM", new Date());
      let params = {};
      params.start = format(startOfMonth(month), "yyyy-MM-dd");
      params.end = format(lastDayOfMonth(month), "yyyy-MM-dd");
      params.professionals = this.params.professionals;
      params.offices = this.params.offices;
      params.tags = this.params.tags;

      this.$http
        .index("sessions/days-with-session", params)
        .then((response) => {
          this.daysWithEvents = response.days;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleOpenNewBookingsDialog() {
      this.$refs.NewBookingsDialog.open();
    },

    async getUnreadBookings() {
      await this.$http.index("sessions/unread-booking").then((response) => {
        this.unreadBookings = response.bookings;
      });
    },

    async markBookingAsRead() {
      await this.$http.update("sessions/unread-booking").then(() => {
        this.unreadBookings = [];
      });
    },
  },
};
</script>

<style></style>
