<template>
  <div>
    <template v-if="selectedValue">
      <app-text-field
        :label="label"
        :value="selectedValue.name"
        readonly
        @click="open()"
        prepend-inner-icon="mdi-magnify"
        @keypress="open()"
        v-bind="$attrs"
      />
    </template>
    <template v-else>
      <div id="acc" @click.stop="open()">
        <app-text-field
          :label="label"
          readonly
          prepend-inner-icon="mdi-magnify"
          @click="open()"
          v-bind="$attrs"
        />
      </div>
    </template>

    <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between" style="width: 100%">
            <div>
              <span>Selecionar {{ label }}</span>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-list>
            <template v-for="(row, index) in offices">
              <v-list-item :key="index" @click="setSelectedValue(row)">
                <v-list-item-content>
                  <v-list-item-title>{{ row.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon></v-list-item-icon>
              </v-list-item>
              <v-divider :key="'div' + index" />
            </template>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <app-back-btn @click="dialog = false" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
  },

  data: () => ({
    label: "Sala",

    dialog: false,
  }),

  computed: {
    offices() {
      return this.$store.state.office.offices;
    },
  },

  watch: {
    value() {
      this.selectedValue = this.value;
    },
  },

  created() {
    this.selectedValue = this.value;
  },

  methods: {
    async open() {
      this.dialog = true;
    },

    setSelectedValue(selectedValue) {
      this.selectedValue = selectedValue;

      this.$emit("input", selectedValue);

      this.dialog = false;
    },

    clear() {
      this.selectedValue = null;
    },
  },
};
</script>
