<template>
  <div>
    <div v-show="!expand">
      <div class="text-center" style="width: 100%">
        <v-btn fab text small color="grey darken-2" @click="$emit('prev')"
          ><v-icon> mdi-chevron-left </v-icon></v-btn
        >
        <v-btn text color="primary" @click="expand = true">{{
          dateLabel
        }}</v-btn>
        <v-btn fab text small color="grey darken-2" @click="$emit('next')"
          ><v-icon> mdi-chevron-right </v-icon></v-btn
        >
      </div>
      <v-slide-group
        :value="dayIndex"
        @change="handleDayChange($event)"
        center-active
        active-class="selected"
        mandatory
      >
        <v-slide-item
          v-for="(day, index) in days"
          :key="index"
          v-slot="{ toggle, active }"
        >
          <div class="text-center">
            <small class="text--disabled">
              {{ getDayOfWeek(day) }}
            </small>
            <br />
            <template v-if="active">
              <v-btn
                class="mx-1 mb-3 accent"
                fab
                small
                @click="toggle"
                elevation="0"
                color="primary"
              >
                {{ getDay(day) }}
                <div
                  class="v-date-picker-table__events mt-5"
                  v-if="hasEventOn(day)"
                  style="padding-right: 2px"
                >
                  <div class="primary"></div>
                </div>
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                class="mx-1 mb-3"
                fab
                small
                @click="toggle"
                elevation="0"
                color="white"
              >
                {{ getDay(day) }}
                <div
                  class="v-date-picker-table__events mt-5"
                  v-if="hasEventOn(day)"
                  style="padding-right: 2px"
                >
                  <div class="primary"></div>
                </div>
              </v-btn>
            </template>
          </div>
        </v-slide-item>
      </v-slide-group>
    </div>
    <div v-show="expand">
      <v-date-picker
        :value="isoDate"
        :full-width="true"
        no-title
        event-color="primary"
        @input="handleCalendarInput($event)"
        @update:picker-date="handleDatePickerChange($event)"
        :events="events"
      ></v-date-picker>
    </div>
  </div>
</template>

<script>
import {
  format,
  parseISO,
  lastDayOfMonth,
  startOfMonth,
  eachDayOfInterval,
} from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export default {
  props: {
    value: {},
    dateLabel: {},
    events: {
      default: [],
    },
  },

  data: () => ({
    dateObject: null,

    expand: false,
  }),

  created() {
    this.dateObject = new Date();
  },

  computed: {
    dayIndex() {
      return this.dateObject.getDate() - 1;
    },

    days() {
      return eachDayOfInterval({
        start: startOfMonth(this.dateObject),
        end: lastDayOfMonth(this.dateObject),
      });
    },

    isoDate() {
      return format(this.dateObject, "yyyy-MM-dd");
    },
  },

  watch: {
    value(val) {
      this.dateObject = parseISO(val);
    },
  },

  methods: {
    getDay(date) {
      return format(date, "d");
    },

    getDayOfWeek(date) {
      return format(date, "iiiii", { locale: ptBR });
    },

    handleDayChange(input) {
      this.dateObject.setDate(input + 1);

      this.$emit("input", format(this.dateObject, "yyyy-MM-dd"));
    },

    handleCalendarInput(input) {
      this.dateObject = parseISO(input);

      this.expand = false;

      this.$emit("input", format(this.dateObject, "yyyy-MM-dd"));
    },

    handleDatePickerChange(event) {
      this.$emit("update:picker-date", event);
    },

    hasEventOn(day) {
      return this.events.includes(format(day, "yyyy-MM-dd"));
    },
  },
};
</script>

<style scoped>
.selected {
  color: slateblue;
}
</style>