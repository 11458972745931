<template>
  <v-select
    v-bind="$attrs"
    solo
    flat
    v-on="$listeners"
    :label="label"
    :items="colors"
    hide-details="auto"
    :item-value="(val) => val.color"
  >
    <template v-slot:item="{ item }">
      <v-chip class="mr-2" :color="item.color" label></v-chip>{{ item.name }}
    </template>
    <template v-slot:selection="{ item }">
      <v-chip class="mr-2" :color="item.color" label></v-chip>{{ item.name }}
    </template>
  </v-select>
</template>

<script>
export default {

  props: {
    label: {
      default: 'Cor'
    },
  },

  data: () => ({
    colors: [
      { name : 'Cor Padrão', color : '#d1c4e9'},
      { name : 'Azul', color : '#b3e5fc'},
      { name : 'Amarelo', color : '#fff59d'},
      { name : 'Verde', color : '#b2dfdb'},
      { name : 'Rosa', color : '#ffcdd2'},
      { name : 'Laranja', color : '#ffe082'},
      { name : 'Roxo', color : '#e1bee7'},
      { name : 'Cinza', color : '#eeeeee'},
    ],
  }),
};
</script>

<style>
</style>