<template>
  <v-menu bottom right>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="{ ...attrs, ...$attrs }" v-on="on">
        <v-icon class="mr-md-2 mr-0">
          {{ typeToLabel[value].icon }}
        </v-icon>
        <span class="d-none d-md-inline">{{ typeToLabel[value].label }}</span>
        <v-icon class="d-none d-md-inline" right> mdi-menu-down </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="handleInput('month')">
        <v-list-item-action
          ><v-icon> mdi-calendar-month-outline </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Mês </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="handleInput('week')">
        <v-list-item-action
          ><v-icon> mdi-table-large </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Semana </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="handleInput('4day')">
        <v-list-item-action><v-icon> mdi-table </v-icon> </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> 4 dias </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="handleInput('day')">
        <v-list-item-action
          ><v-icon> mdi-table-column</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Dia </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="showTypeCategory"
        @click="handleInput('categoryProfessional')"
      >
        <v-list-item-action><v-icon> mdi-account</v-icon> </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Profissional </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="hasOffices" @click="handleInput('categoryOffice')">
        <v-list-item-action
          ><v-icon> mdi-chair-rolling</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Sala </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {},
    isClinic: {
      default: false,
    },
    hasOffices: {
      default: false,
    },
  },

  data: () => ({
    typeToLabel: {
      month: { label: "Mês", icon: "mdi-calendar-month" },
      week: { label: "Semana", icon: "mdi-table-large" },
      "4day": { label: "4 dias", icon: "mdi-table" },
      day: { label: "Dia", icon: "mdi-table-column" },
      categoryProfessional: { label: "Por Profissional", icon: "mdi-account" },
      categoryOffice: { label: "Por Sala", icon: "mdi-chair-rolling" },
    },
  }),

  computed: {
    showTypeCategory() {
      return this.isClinic && this.$acl.can("can_manage_all_calendar");
    },
  },

  methods: {
    handleInput(value) {
      localStorage.setItem("app-calendar-type", value);
      this.$emit("input", value);
    },
  },
};
</script>

<style>
</style>