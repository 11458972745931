<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text class="d-flex">
        <div class="pr-6">
          <v-avatar color="#fbeddd">
            <app-icon color="warning">warning</app-icon>
          </v-avatar>
        </div>
        <div>
          <h3 class="mb-4">Deseja mesmo sair?</h3>
          Todas suas alterações serão perdidas, deseja mesmo sair sem salvar?
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="text-right">
        <v-btn @click="discard()" text> Sair sem salvar </v-btn>
        <v-btn @click="save()" dark color="primary"> Salvar alterações </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,

    resolve: null,

    reject: null,
  }),

  methods: {
    askForSave() {
      return new Promise((resolve, reject) => {
        this.resolve = resolve;

        this.reject = reject;

        this.dialog = true;
      });
    },

    save() {
      this.dialog = false;
      this.resolve("ok");
    },

    discard() {
      this.dialog = false;
      this.reject();
    },
  },
};
</script>
