<template>
  <div id="filter">
    <v-list v-if="hasTags" dense>
      <v-list-group
        :value="showSection('tags')"
        @click="showSection('tags', !showSection('tags'))"
      >
        <template v-slot:prependIcon>
          <v-icon>mdi-tag</v-icon>
        </template>

        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title> Tags </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item-group>
          <v-list-item inactive @click="selectAll('tags')">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  :indeterminate="isIndeterminate('tags')"
                  :input-value="active"
                  color="primary"
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ selectAllText("tags") }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>

        <v-list-item-group v-model="searchParams.tags" multiple>
          <v-list-item
            v-for="(row, index) in tags"
            :key="index"
            :value="row.id"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active" color="primary" />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  <v-icon class="mr-2" small :color="row.color">
                    mdi-tag
                  </v-icon>
                  {{ row.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list-group>
    </v-list>

    <v-list v-if="showProfessionalsList" dense>
      <v-list-group
        :value="showSection('professionals')"
        @click="showSection('professionals', !showSection('professionals'))"
      >
        <template v-slot:prependIcon>
          <app-icon>badge</app-icon>
        </template>

        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title> Profissionais </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item-group>
          <v-list-item inactive @click="selectAll('professionals')">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  :indeterminate="isIndeterminate('professionals')"
                  :input-value="!active"
                  color="primary"
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ selectAllText("professionals") }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>

        <v-list-item-group v-model="searchParams.professionals" multiple>
          <v-list-item
            v-for="(row, index) in professionals"
            :key="index"
            :value="row.id"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active" color="primary" />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ row.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list-group>
    </v-list>

    <v-list v-if="showProfessionalsList && hasOffices" dense>
      <v-list-group
        :value="showSection('offices')"
        @click="showSection('offices', !showSection('offices'))"
      >
        <template v-slot:prependIcon>
          <app-icon>mdi-chair-rolling</app-icon>
        </template>

        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title> Salas </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item-group>
          <v-list-item inactive @click="selectAll('offices')">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  :indeterminate="isIndeterminate('offices')"
                  :input-value="!active"
                  color="primary"
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ selectAllText("offices") }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>

        <v-list-item-group v-model="searchParams.offices" multiple>
          <v-list-item
            v-for="(row, index) in offices"
            :key="index"
            :value="row.id"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active" color="primary" />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ row.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      searchParams: {
        tags: [],
        professionals: [],
        offices: [],
      },
    };
  },

  watch: {
    value() {
      this.searchParams = this.value;
    },

    searchParams: {
      handler() {
        this.$emit("input", this.searchParams);
      },
      deep: true,
    },
  },

  created() {
    this.searchParams = this.value;
  },

  computed: {
    professionals() {
      return this.$attrs.professionals ?? [];
    },
    offices() {
      return this.$attrs.offices ?? [];
    },
    tags() {
      return this.$attrs.tags ?? [];
    },
    showProfessionalsList() {
      return this.$attrs.showProfessionalsList ?? false;
    },
    hasTags() {
      return this.$attrs.hasTags ?? false;
    },
    hasOffices() {
      return this.$attrs.hasOffices ?? false;
    },

    selectedCount() {
      return {
        tags: this.searchParams.tags.length ?? 0,
        professionals: this.searchParams.professionals.length ?? 0,
        offices: this.searchParams.offices.length ?? 0,
      };
    },

    showSection() {
      return (section, value) => {
        if (value !== undefined) {
          localStorage.setItem(`show-${section}-list`, value);
        }
        return localStorage.getItem(`show-${section}-list`) === "true";
      };
    },
  },

  methods: {
    isIndeterminate(arr) {
      return (
        this.selectedCount[arr] !== 0 &&
        this.selectedCount[arr] !== this[arr].length
      );
    },

    selectAll(arr) {
      if (this.selectedCount[arr] == this[arr].length) {
        this.searchParams[arr] = [];
      } else {
        this.searchParams[arr] = this[arr].map((row) => row.id);
      }
    },

    selectAllText(arr) {
      return this.searchParams[arr].length === this[arr].length
        ? "Desmarcar todos"
        : "Marcar todos";
    },
  },
};
</script>

<style lang="scss"  >
#filter {
  .v-list-item {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .v-list-item__icon {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .v-list-item__action {
    margin-right: 5px;
    transform: scale(0.8);
  }
}
</style>