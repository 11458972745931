<template>
  <v-btn v-bind="$attrs" @click.stop="sendLinkVideoCall()" :loading="loading"
    ><v-icon class="mr-2" v-bind="$attrs" v-if="icon" v-text="icon" />
    {{ label }}</v-btn
  >
</template>

<script>
export default {
  props: {
    session: {},
    label: {},
    icon: {},
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    sendLinkVideoCall() {
      this.loading = true;
      this.$http
        .index("sessions/send-link-video-call", {
          session_id: this.session.id,
        })
        .then((response) => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>