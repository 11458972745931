var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"filter"}},[(_vm.hasTags)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-group',{attrs:{"value":_vm.showSection('tags')},on:{"click":function($event){_vm.showSection('tags', !_vm.showSection('tags'))}},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-tag")])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Tags ")])],1)]},proxy:true}],null,false,3752934230)},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"inactive":""},on:{"click":function($event){return _vm.selectAll('tags')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate('tags'),"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.selectAllText("tags"))+" ")])],1)]}}],null,false,1499662247)})],1),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.searchParams.tags),callback:function ($$v) {_vm.$set(_vm.searchParams, "tags", $$v)},expression:"searchParams.tags"}},_vm._l((_vm.tags),function(row,index){return _c('v-list-item',{key:index,attrs:{"value":row.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":row.color}},[_vm._v(" mdi-tag ")]),_vm._v(" "+_vm._s(row.name)+" ")],1)],1)]}}],null,true)})}),1)],1)],1):_vm._e(),(_vm.showProfessionalsList)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-group',{attrs:{"value":_vm.showSection('professionals')},on:{"click":function($event){_vm.showSection('professionals', !_vm.showSection('professionals'))}},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('app-icon',[_vm._v("badge")])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Profissionais ")])],1)]},proxy:true}],null,false,1362115115)},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"inactive":""},on:{"click":function($event){return _vm.selectAll('professionals')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate('professionals'),"input-value":!active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.selectAllText("professionals"))+" ")])],1)]}}],null,false,170816582)})],1),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.searchParams.professionals),callback:function ($$v) {_vm.$set(_vm.searchParams, "professionals", $$v)},expression:"searchParams.professionals"}},_vm._l((_vm.professionals),function(row,index){return _c('v-list-item',{key:index,attrs:{"value":row.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(row.name)+" ")])],1)]}}],null,true)})}),1)],1)],1):_vm._e(),(_vm.showProfessionalsList && _vm.hasOffices)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-group',{attrs:{"value":_vm.showSection('offices')},on:{"click":function($event){_vm.showSection('offices', !_vm.showSection('offices'))}},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('app-icon',[_vm._v("mdi-chair-rolling")])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Salas ")])],1)]},proxy:true}],null,false,1834981023)},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"inactive":""},on:{"click":function($event){return _vm.selectAll('offices')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate('offices'),"input-value":!active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.selectAllText("offices"))+" ")])],1)]}}],null,false,2944416134)})],1),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.searchParams.offices),callback:function ($$v) {_vm.$set(_vm.searchParams, "offices", $$v)},expression:"searchParams.offices"}},_vm._l((_vm.offices),function(row,index){return _c('v-list-item',{key:index,attrs:{"value":row.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(row.name)+" ")])],1)]}}],null,true)})}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }