<template>
  <div id="alert-bookings">
    <v-alert text color="primary">
      {{ text }}
      <v-btn text color="primary" @click="handleShow()">
        Clique aqui para ver
      </v-btn>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    unreadBookings: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    text() {
      if (this.unreadBookings.length > 1) {
        return `Você tem ${this.unreadBookings.length} novas sessões agendadas pelo paciente!`;
      } else {
        return `Você tem uma nova sessão agendada pelo paciente!`;
      }
    },
  },

  methods: {
    handleShow() {
      this.$emit("show");
    },
  },
};
</script>

<style scoped lang="sass">
#alert-bookings
  div.v-alert
    // background: linear-gradient(90deg, rgba(144, 127, 245, 0.32) 0%, rgba(144, 127, 245, 0.0768) 57.12%)
    border-radius: 22px !important
</style>