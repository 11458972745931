<template>
  <div>
    <template v-for="time of timeArray">
      <div v-if="checkIfIsWorkingHour(time)" style="height: 4px"></div>
      <div v-else style="background-color: #eeeeee; height: 4px"></div>
    </template>
  </div>
</template>

<script>
import {
  format,
  parse,
  addMinutes,
} from "date-fns";

export default {
  props: {
    time: {
      type: String,
      required: true,
    },
    weekday: {
      type: Number,
      required: true,
    },
    workingHours: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      timeArray: [],
    };
  },

  computed: {
    workingHoursOfWeekDay() {
      return this.workingHours.filter(
        (row) => row.week_day_number == Number(this.weekday) + 1
      );
    },
  },

  created() {
    this.makeTimeArray();
  },

  methods: {
    makeTimeArray() {
      let time = parse(this.time, "HH:mm", new Date());

      let timeArray = [];

      for (let i = 0; i < 12; i++) {
        timeArray.push(time);
        time = addMinutes(time, 5);
      }

      this.timeArray = timeArray;
    },

    checkIfIsWorkingHour(time) {
      let isWorkable = false;

      this.workingHoursOfWeekDay.forEach((row) => {
        let start = parse(row.starts_at, "HH:mm:ss", new Date());
        let end = parse(row.ends_at, "HH:mm:ss", new Date());

        if (time >= start && time < end) {
          isWorkable = true;
        } 
      });

      return isWorkable;
    },
  },
};
</script>
