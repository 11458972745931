<template>
  <v-dialog
    persistent
    scrollable
    width="400"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title> Configurar Agenda </v-card-title>
      <v-card-text class="pt-4">
        <v-row class="mb-4">
          <v-col>
            <app-time-picker
              v-model="form.calendar_starts_at"
              label="Hora de inicio da agenda"
            />
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col>
            <app-time-picker
              v-model="form.calendar_ends_at"
              label="Hora de fim da agenda"
            />
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col>
            <app-select
              v-model="form.calendar_interval"
              label="Intervalos da agenda"
              :items="minutesInterval"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Exibir dias da semana:
            <v-switch
              v-model="form.calendar_show_days"
              :value="0"
              hide-details
              label="Domingo"
            />
            <v-switch
              v-model="form.calendar_show_days"
              :value="1"
              hide-details
              label="Segunda-feira"
            />
            <v-switch
              v-model="form.calendar_show_days"
              :value="2"
              hide-details
              label="Terça-feira"
            />
            <v-switch
              v-model="form.calendar_show_days"
              :value="3"
              hide-details
              label="Quarta-feira"
            />
            <v-switch
              v-model="form.calendar_show_days"
              :value="4"
              hide-details
              label="Quinta-feira"
            />
            <v-switch
              v-model="form.calendar_show_days"
              :value="5"
              hide-details
              label="Sexta-feira"
            />
            <v-switch
              v-model="form.calendar_show_days"
              :value="6"
              hide-details
              label="Sábado"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="save()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    minutesInterval: [
      { text: "5 minutos", value: 5 },
      { text: "15 minutos", value: 15 },
      { text: "30 minutos", value: 30 },
      { text: "40 minutos", value: 40 },
      { text: "45 minutos", value: 45 },
      { text: "60 minutos", value: 60 },
    ],

    form: {
      calendar_starts_at: null,
      calendar_ends_at: null,
      calendar_interval: null,
      calendar_show_days: [],
    },
  }),

  created() {
    // set company data
    this.setFormData(this.$store.state.auth.company);
  },

  methods: {
    open() {
      this.dialog = true;
    },

    setFormData(company) {
      this.form.calendar_starts_at = company.calendar_starts_at;
      this.form.calendar_ends_at = company.calendar_ends_at;
      this.form.calendar_interval = company.calendar_interval;
      this.form.calendar_show_days = JSON.parse(company.calendar_show_days);
    },

    async save() {
      this.$loading.start();
      await this.$http
        .store("company/calendar-settings", this.form)
        .then((response) => {
          this.$auth.getLoginData();
          this.dialog = false;
        });

      this.$loading.finish();
    },
  },
};
</script>

<style></style>
